import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import faqImg from "../images/faq/faq.png"
import seoContent from "../helpers/seoContent"

const FAQ = ({ data }) => {
  const renderFAQs = faqs => {
    if (faqs && faqs.length) {
      return faqs.map((faq, i) => {
        let question = faq.node.question
        let answer = faq.node.answer.answer
        return (
          <div className="faq-item-wrap" key={i}>
            <div className="faq-item-question">{question}</div>
            <div className="faq-item-answer">{answer}</div>
          </div>
        )
      })
    }
  }
  const { siteDescriptions } = seoContent
  return (
    <Layout>
      <SEO title="FAQ" description={siteDescriptions.default} />
      <div id="faq">
        <div className="faq-header">
          <h1>Frequently Asked Questions</h1>
        </div>
        <div className="faq-body">
          <div className="faq-img-wrap">
            <img className="faq-img" src={faqImg} />
          </div>
          <div className="faq-items">
            {renderFAQs(data.allContentfulFaq.edges)}
          </div>
          <div className="faq-footer">
            Thank you for reading! Please submit any other questions you have
            via my{" "}
            <Link to="/contact/" className="faq-link">
              Contact
            </Link>{" "}
            page and feel free to get in touch personally.
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FAQ

export const query = graphql`
  query {
    allContentfulFaq {
      edges {
        node {
          question
          answer {
            answer
          }
        }
      }
    }
  }
`
